@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.reviews {
  $block-name: &;

  //   #{$block-name} {
  //   }

  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;

  @include mq($to: lg) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include mq($to: md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__top {
    margin-bottom: 50px;

    @include mq($to: lg) {
      margin-bottom: 30px;
    }
  }

  &__top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($to: xl) {
      justify-content: center;
    }
  }

  &__title {
    @include mq($to: xl) {
      text-align: center;
    }
  }

  &__button {
    flex: 0 0 auto;
    margin-left: 40px;

    @include mq($to: xl) {
      display: none;
    }
  }

  &__body {
  }

  &__awards {
    min-height: 120px;
  }
}
