@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.carousel {
  margin-left: -10px;
  margin-right: -10px;

  .swiper {
    box-sizing: border-box;
    overflow: visible;
  }

  .swiper-slide {
    box-sizing: border-box;
    width: 422px;
    padding-left: 10px;
    padding-right: 10px;

    @include mq($to: md) {
      width: 293px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  .review {
    height: 535px;

    @include mq($to: md) {
      height: 421px;
    }
  }

  &__clutch-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__clutch {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #e7e0ff;
    height: 535px;
    padding: 30px;
    text-decoration: none;
    transition: transform $transition-time--slow;
    will-change: transform;

    @include mq($to: md) {
      height: 421px;
      padding: 20px;
    }

    &:hover {
      transform: translateY(-12px);
    }

    &:active {
      transform: translateY(-5px);
    }
  }

  &__clutch-logo {
    margin-bottom: 16px;
  }

  &__clutch-stars {
    font-size: 0;
    line-height: 0;
    display: flex;
    flex-wrap: nowrap;
    color: #ff4d39;

    @include mq($to: md) {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__clutch-star-icon {
  }

  &__clutch-title,
  &__clutch-reviews {
    color: #292d36;
    text-align: center;
    font-family: $font-family--headings;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;

    @include mq($to: md) {
      font-size: 14px;
    }
  }

  &__clutch-title {
    margin-bottom: 16px;
  }

  &__clutch-reviews {
    margin-top: 12px;
  }
  $block-name: &;

  img {
    @include mq($to: md) {
      max-width: 120px;
    }
  }
}
